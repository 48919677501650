import React, { useState } from "react";
import styles from "./Contatos.module.scss";
import "../../css/accordionbootstrap.css";
import { motion } from "framer-motion";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Row,
} from "reactstrap";
import { useMobile } from "../../hooks/useMobile";
import { Instagram } from "react-bootstrap-icons";

const Contatos = () => {
  const classeContainer = `container-fluid ${styles.container}`;
  const visible = { opacity: 1, y: 0, transition: { duration: 0.3 } };
  const { isMobile } = useMobile();
  const [open, setOpen] = useState("0");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const abrirInstagram = () => {
    const url = "https://www.instagram.com/voktum/";
    window.open(url, "_blank");
  };
  const conteudo = (
    <Row className={styles.conteudo}>
      {isMobile && (
        <Col>
          <div className={styles.linhaMobile} />
        </Col>
      )}
      <Col lg={6}>
        <div className={styles.textosContatos}>
          <h1>contatos</h1>
          <h4>telefone:</h4>
          <p>+55 31 9 8107-5420</p>
          <h4>e-mail</h4>
          <p>vendas@voktum.com.br</p>
          <h4>endereço</h4>
          <p>
            Av. Professor Cristóvam dos Santos, 270 - Belvedere, Belo Horizonte
            - MG, 30320-510
          </p>
          <h4>Horário de atendimento</h4>
          <p>
            De terça a sexta, das 10 horas às 19 horas, e sábado, das 9 horas às
            14 horas.
          </p>
          <h4 className="mt-5">siga nossas redes</h4>
          <div onClick={() => abrirInstagram()} className="pointer">
            <Instagram size={20} className="mt-1 mb-4" />
          </div>
        </div>
      </Col>
      {/* <Col lg={6}>
        <div className={styles.duvidasFrequentes}>
          <h1>dúvidas frequentes</h1>
          <Accordion open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="1">
                Formas de Pagamento
              </AccordionHeader>
              <AccordionBody accordionId="1">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the s standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book. It has survived
                not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged. It was popularised
                in the 1960s with the release of Letraset sheets containing
                Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="2">Prazos de Entrega</AccordionHeader>
              <AccordionBody accordionId="2">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the s standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book. It has survived
                not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged. It was popularised
                in the 1960s with the release of Letraset sheets containing
                Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="3">
                Trocas e Devoluções
              </AccordionHeader>
              <AccordionBody accordionId="3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the s standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book. It has survived
                not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged. It was popularised
                in the 1960s with the release of Letraset sheets containing
                Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="4">
                Posso Customizar meu produto?
              </AccordionHeader>
              <AccordionBody accordionId="4">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the s standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book. It has survived
                not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged. It was popularised
                in the 1960s with the release of Letraset sheets containing
                Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="5">
                Acompanhe o seu pedido
              </AccordionHeader>
              <AccordionBody accordionId="5">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the s standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book. It has survived
                not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged. It was popularised
                in the 1960s with the release of Letraset sheets containing
                Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="6">
                Cookies e Segurança
              </AccordionHeader>
              <AccordionBody accordionId="6">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the s standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book. It has survived
                not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged. It was popularised
                in the 1960s with the release of Letraset sheets containing
                Lorem Ipsum passages, and more recently with desktop publishing
                software like Aldus PageMaker including versions of Lorem Ipsum.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
      </Col> */}
    </Row>
  );

  return (
    <motion.article
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <motion.div
        className={classeContainer}
        variants={{ hidden: { opacity: 0 }, visible }}
      >
        {conteudo}
      </motion.div>
    </motion.article>
  );
};

export default React.memo(Contatos);
