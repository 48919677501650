import { memo, useEffect, useRef, useState } from "react";
import logo from "../../images/logo_inicio_voktum.png";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Footer from "../../layouts/footer/Footer";

const Inicio = () => {
  const produtoRef = useRef(null);
  const produtoHoverRef = useRef(null);
  const designerRef = useRef(null);
  const designerHoverRef = useRef(null);
  const trocarOpacidade = (paraEsconder, paraMostrar) => {
    const tempo = 270;
    fadeIn(paraMostrar.current, tempo);
    fadeOut(paraEsconder.current, tempo);
  };

  const mostrar = (element) => {
    element.style.visibility = "visible";
  };

  const fadeOut = (element, duration) => {
    let opacity = 1;
    const interval = 50;
    const gap = interval / duration;
    const fading = setInterval(() => {
      opacity -= gap;
      if (opacity <= 0) {
        clearInterval(fading);
      }
      element.style.opacity = opacity;
    }, interval);
  };

  const fadeIn = (element, duration) => {
    mostrar(element);
    let opacity = 0;
    const interval = 50;
    const gap = interval / duration;

    const fading = setInterval(() => {
      opacity += gap;
      if (opacity >= 1) {
        clearInterval(fading);
        opacity = 1;
      }
      element.style.opacity = opacity;
    }, interval);
  };

  return (
    <div className="inicio">
      <div className="conteudo">
        <Container>
          <Row>
            <Col sm={12}>
              <img className="logo-voktum" src={logo} alt="logo" />
            </Col>
          </Row>
          <Row>
            <Container className="d-flex justify-content-center">
              <Row className="position-relative">
                <Col>
                  <div
                    onMouseEnter={() =>
                      trocarOpacidade(produtoRef, produtoHoverRef)
                    }
                    onMouseLeave={() =>
                      trocarOpacidade(produtoHoverRef, produtoRef)
                    }
                  >
                    <Link
                      className="link-produto"
                      ref={produtoRef}
                      to={{
                        pathname: "/produtos/",
                      }}
                    >
                      produtos
                    </Link>

                    <Link
                      className="link-produto-hover"
                      ref={produtoHoverRef}
                      to={{ pathname: "/produtos/" }}
                    >
                      produtos
                    </Link>
                  </div>
                  <span>|</span>
                  <div
                    onMouseEnter={() =>
                      trocarOpacidade(designerRef, designerHoverRef)
                    }
                    onMouseLeave={() =>
                      trocarOpacidade(designerHoverRef, designerRef)
                    }
                  >
                    <Link
                      className="link-designer"
                      ref={designerRef}
                      to={{
                        pathname: "/designers/",
                      }}
                    >
                      designers
                    </Link>
                    <Link
                      className="link-designer-hover"
                      ref={designerHoverRef}
                      to={{
                        pathname: "/designers/",
                      }}
                    >
                      designers
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </div>
      {/* <Footer />  */}
    </div>
  );
};

export default memo(Inicio);
